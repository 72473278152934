<template>

  <v-card id="planeditor">
    <v-card-actions>
      <v-card-title>
        <template v-if="mode === 'new'">Sammlung anlegen</template>
        <template v-else-if="mode === 'duplicate'">Sammlung duplizieren</template>
        <template v-else-if="mode === 'group'">Sammlungsgruppe erstellen</template>
        <template v-else-if="mode === 'group-duplicate'">Sammlungsgruppe duplizieren</template>
        <template v-else>Sammlung bearbeiten</template>
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        elevation="0"
        fab
        text
        @click="abort"
      >
        <span class="font-weight-bold" style="font-size: 1.2em;">X</span>
      </v-btn>
    </v-card-actions>
    <v-spacer></v-spacer>
    <v-divider></v-divider>
    <v-card-text style="">
      <v-form
        ref="form"
        v-model="formValid"
      >
        <v-row class="mt-2">
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="collectionName"
              :disabled="!this.isTitleEditable"
              :label="$tc('collectionPlan.labels.collectionPurpose')"
              :rules="collectionNameRules"
              dense
              hint="Vollständiger Sammlungszweck für die Abrechnung"
              outlined
              placeholder="Für ..."
              required
              @input="changeCollectionName"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="collectionVariant"
              :disabled="!isEditable"
              :items="variantOptions"
              :label="$tc('collectionPlan.labels.collectionType')"
              :menu-props="{ offsetY: true }"
              dense
              hide-details="auto"
              item-text="name"
              item-value="id"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="['group', 'group-duplicate'].includes(mode)">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-data-table
                  :headers="groupCandidateHeaders"
                  :items="groupCandidates"
                  :hide-default-footer="true"
                >
                  <template #item.weight="{ item }">
                    <v-text-field
                      v-model.number="item.weight"
                      @click:prepend="changeWeight(item, -1)"
                      @click:append-outer="changeWeight(item, 1)"
                      prepend-icon="mdi-minus"
                      append-outer-icon="mdi-plus"
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-stepper
              v-model="currentStep"
              vertical
            >
              <v-stepper-step
                :complete="currentStep > stepperSteps.DEVICE_SELECTION.value"
                :rules="[atLeastOneDeviceClassEnabledRule]"
                :step="stepperSteps.DEVICE_SELECTION.value"
                class="stepper"
                @click="currentStep = stepperSteps.DEVICE_SELECTION.value"
              >
                {{ !useGroupMode ? $t('collectionPlan.steps.chooseDevices') : $t('collectionPlan.steps.previewDevices') }}
                <small v-if="showSummaries(stepperSteps.DEVICE_SELECTION.value)">
            <span v-for="deviceClass in targets" :key="'sum-'+deviceClass" class="pr-2">
              <device-icon :device-class="deviceClass" small></device-icon> <span>{{
                DEVICE_CLASSES[deviceClass]
              }}</span>
            </span>
                </small>
              </v-stepper-step>

              <v-stepper-content
                :step="stepperSteps.DEVICE_SELECTION.value">
                <v-row v-if="isEditable">
                  <v-col cols="12">
                    <template v-html="$t('collectionPlan.hints.visibilityOnSimulatedDisplays')"/>
                  </v-col>
                </v-row>

                <div class="d-flex flex-wrap">
                  <div v-for="(deviceColumn, index) in editorLayout" :key="index" class="px-4 mr-auto">
                    <template v-if="allowDeviceSelection">
                      <v-checkbox
                        v-for="deviceClass in deviceColumn.deviceClasses"
                        :key="'dc-'+deviceClass"
                        v-model="selectedTargets[deviceClass]"
                        :rules="[atLeastOneDeviceClassEnabledRule]"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <template v-if="!ownDeviceClasses.has(deviceClass)">(</template>
                          {{ DEVICE_CLASSES[deviceClass] }}
                          <template v-if="!ownDeviceClasses.has(deviceClass)">)</template>
                        </template>
                      </v-checkbox>
                    </template>
                    <template v-else>
                      <div
                        v-for="deviceClass in deviceColumn.deviceClasses"
                        :key="'dc-label-' + deviceClass"
                      >{{ DEVICE_CLASSES[deviceClass] }}</div>
                    </template>
                    <component
                      :is="displayKindComponents[displayKind]"
                      v-for="displayKind in deviceColumn.displayKinds"
                      :key="'dk-'+displayKind"
                      v-model="displayVariants[displayKind]"
                      :class="{disabled: !selectedDisplayKinds[displayKind] && !useGroupMode, 'simulation-wrapper': true, 'my-4': true}"
                      :editable="isEditable && delayedEnableContentEditable"
                      :selected="selectedDisplayKinds[displayKind] || useGroupMode"
                    ></component>
                  </div>
                </div>
                <v-btn
                  class="btn-next"
                  color="primary"
                  @click="currentStep = stepperSteps.DEVICE_SELECTION.value + 1"
                >
                  {{ $t('action.next') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step
                v-if="stepperSteps.PERIOD_SELECTION.enabled"
                :complete="currentStep > stepperSteps.PERIOD_SELECTION.value"
                :rules="step2Rules"
                :step="stepperSteps.PERIOD_SELECTION.value"
                class="stepper"
                @click="currentStep = stepperSteps.PERIOD_SELECTION.value"
              >
                {{ $t('collectionPlan.steps.collectionPeriod') }}
                <small v-if="showSummaries(stepperSteps.PERIOD_SELECTION.value)">
                  {{ step2summary }}
                </small>
              </v-stepper-step>

              <v-stepper-content
                v-if="stepperSteps.PERIOD_SELECTION.enabled"
                :step="stepperSteps.PERIOD_SELECTION.value"
              >
                <v-row class="mt-3">
                  <v-col cols="12">
                    <!-- Implicitly assume that dateOptions will contain "custom_date" and optionally more -->
                    <v-autocomplete
                      v-if="isEditable && dateOptions.length > 1"
                      v-model="selectedDate"
                      :disabled="!isEditable || dateOptions.length === 1"
                      :items="dateOptions"
                      :label="$tc('collectionPlan.labels.timingAndPeriod')"
                      :menu-props="{ offsetY: true }"
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="url"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="selectedDate !== 'custom_date' && isNew" no-gutters>
                  <v-col cols="12">
                    <p>{{ textToShow }}</p>
                  </v-col>
                </v-row>
                <v-row v-if="selectedDate === 'custom_date'">
                  <v-col cols="6">
                    <v-datetime-picker
                      id="start"
                      v-model="start"
                      :clearText="$tc('action.cancel')"
                      :date-picker-props="datePickerProps"
                      :time-picker-props="timePickerProps"
                      :disabled="!isEditable"
                      :label="start ? $tc('collectionPlan.labels.from') : $tc('collectionPlan.labels.fromNow')"
                      :min-datetime="startReference.format()"
                      :okText="$tc('action.confirm')"
                      :text-field-props="startDateTextFieldProps"
                      dateFormat="dd.MM.yyyy,"
                      timeFormat="HH:mm"
                    >
                      <template slot="dateIcon">
                        <v-icon>mdi-calendar-month</v-icon>
                      </template>
                      <template slot="timeIcon">
                        <v-icon>mdi-clock-time-four-outline</v-icon>
                      </template>
                    </v-datetime-picker>
                  </v-col>
                  <v-col cols="6">
                    <v-datetime-picker
                      id="end"
                      v-model="end"
                      :clearText="$tc('action.cancel')"
                      :date-picker-props="datePickerProps"
                      :disabled="!isEditable"
                      :label="end ? $tc('collectionPlan.labels.to') : $tc('collectionPlan.labels.toInfinity')"
                      :okText="$tc('action.confirm')"
                      :text-field-props="endDateTextFieldProps"
                      :time-picker-props="timePickerProps"
                      dateFormat="dd.MM.yyyy,"
                      timeFormat="HH:mm"
                    >
                      <template slot="dateIcon">
                        <v-icon>mdi-calendar-month</v-icon>
                      </template>
                      <template slot="timeIcon">
                        <v-icon>mdi-clock-time-four-outline</v-icon>
                      </template>
                    </v-datetime-picker>
                  </v-col>
                  <!-- TODO: only show when donation time span is greater than 6 months and the app was selected -->
                  <v-col cols="12">
                    <v-checkbox
                      v-if="collectionVariant === '_donation' && recurringPaymentsEnabled"
                      v-model="allowRecurring"
                      :disabled="!isEligibleForRecurringDonation"
                      :label="$t('collectionPlan.labels.allowRecurring')"
                    />
                  </v-col>
                </v-row>
                <v-btn
                  class="btn-next"
                  color="primary"
                  @click="currentStep = stepperSteps.PERIOD_SELECTION.value + 1"
                >
                  {{ $t('action.next') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step
                v-if="stepperSteps.MORE_INFORMATION.enabled"
                :complete="currentStep > stepperSteps.MORE_INFORMATION.value"
                :rules="step3Rules"
                :step="stepperSteps.MORE_INFORMATION.value"
                class="stepper"
                @click="currentStep = stepperSteps.MORE_INFORMATION.value"
              >
                {{ $t('collectionPlan.steps.moreInformation') }}
                <small v-if="detailDescription === '' && showSummaries(stepperSteps.MORE_INFORMATION.value)">Kein
                  Beschreibungstext vorhanden</small>
                <small v-else-if="showSummaries(stepperSteps.MORE_INFORMATION.value)">{{
                    $t('collectionPlan.labels.descriptionText')
                  }}: {{ detailDescription.length }} {{ $t('collectionPlan.labels.characters') }}</small>
              </v-stepper-step>

              <v-stepper-content v-if="stepperSteps.MORE_INFORMATION.enabled" :step="stepperSteps.MORE_INFORMATION.value">
                <p v-html="$t('collectionPlan.hints.visibleForDonors')"></p>
                <v-textarea
                  v-if="isRestrictedEditable"
                  v-model="detailDescription"
                  :label="$tc('collectionPlan.labels.descriptionText')"
                  :rules="detailDescriptionRules"
                  counter
                  :hint="['group', 'group-duplicate'].includes(mode) ? 'max. 5000 Zeichen' : 'max. 1000 Zeichen'"
                  outlined
                  style="margin-top:0.2em"
                ></v-textarea>
                <span v-else>
              <template v-if="detailDescription === ''">{{
                  $t('collectionPlan.hints.noDescriptionTextAvailable')
                }}</template>
              <template v-else>{{ $t('collectionPlan.labels.descriptionText') }}: {{ detailDescription }}</template>
            </span>
                <v-row v-if="mode !== 'group'">
                  <v-col col="12" sm="9">
                    <v-text-field
                      v-model="meta.external_fields.www"
                      :label="$tc('collectionPlan.labels.website')"
                      dense
                      outlined
                    />
                  </v-col>
                </v-row>

                <v-btn
                  class="btn-next"
                  color="primary"
                  @click="currentStep = stepperSteps.MORE_INFORMATION.value + 1"
                >
                  {{ $t('action.next') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step
                v-if="stepperSteps.COLLECTION_TYPE.enabled"
                :complete="currentStep > stepperSteps.COLLECTION_TYPE.value"
                :step="stepperSteps.COLLECTION_TYPE.value"
                class="stepper"
                @click="currentStep = stepperSteps.COLLECTION_TYPE.value"
              >
                {{ $t('collectionPlan.steps.collectionType') }}
                <small v-if="showSummaries(stepperSteps.COLLECTION_TYPE.value) && foreign">Weiterleitungskollekte</small>
              </v-stepper-step>

              <v-stepper-content
                v-if="stepperSteps.COLLECTION_TYPE.enabled"
                :step="stepperSteps.COLLECTION_TYPE.value"
              >
                <v-row style="max-width:calc(100% - 0.5em);padding-left:0.25em">
                  <v-col class="mt-3" cols="12">
                    <v-checkbox v-model="foreign" label="Weiterleitungskollekte"/>
                  </v-col>
                </v-row>

                <v-row v-if="additionalAccountingFields" style="max-width:calc(100% - 0.5em);padding-left:0.25em">

                  <v-col class="mt-3" cols="12">
                    <v-expansion-panels
                      v-if="foreign"
                      :value="[0]"
                      focusable
                      multiple
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header>{{
                            $t('collectionPlan.labels.foreignCollectionTarget')
                          }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row class="mt-2">
                            <v-col
                              v-if="additionalForeignCollectionFields.includes('Organisation')"
                              cols="12"
                            >
                              <v-text-field
                                v-model="meta.external_fields.Organisation"
                                :label="$tc('collectionPlan.organization')"
                                dense
                                hide-details="true"
                                outlined
                              >
                              </v-text-field>
                            </v-col>
                            <v-col
                              v-if="additionalForeignCollectionFields.includes('Straße')"
                              cols="12"
                              sm="5"
                            >
                              <v-text-field
                                v-model="meta.external_fields.Straße"
                                :label="$tc('contact.street') + ' & ' + $tc('contact.number')"
                                dense
                                hide-details="true"
                                outlined
                              />
                            </v-col>
                            <v-col
                              v-if="additionalForeignCollectionFields.includes('PLZ')"
                              cols="12"
                              sm="3"
                            >
                              <v-text-field
                                v-model="meta.external_fields.PLZ"
                                :label="$tc('contact.zip')"
                                dense
                                hide-details="true"
                                outlined
                              />
                            </v-col>
                            <v-col
                              v-if="additionalForeignCollectionFields.includes('Ort')"
                              cols="12"
                              sm="4"
                            >
                              <v-text-field
                                v-model="meta.external_fields.Ort"
                                :label="$tc('contact.city')"
                                dense
                                hide-details="true"
                                outlined
                              />
                            </v-col>
                            <v-col
                              v-if="additionalForeignCollectionFields.includes('Kontaktperson')"
                              cols="12"
                            >
                              <v-text-field
                                v-model="meta.external_fields.Kontaktperson"
                                :label="$tc('collectionPlan.contactPerson')"
                                dense
                                hide-details="true"
                                outlined
                              />
                            </v-col>
                            <v-col
                              v-if="additionalForeignCollectionFields.includes('Telefon')"
                              cols="6"
                            >
                              <v-text-field
                                v-model="meta.external_fields.Telefon"
                                :label="$tc('general.phone')"
                                dense
                                hide-details="true"
                                outlined
                              />
                            </v-col>
                            <v-col
                              v-if="additionalForeignCollectionFields.includes('Mail')"
                              cols="6"
                            >
                              <v-text-field
                                v-model="meta.external_fields.Mail"
                                :label="$tc('general.email')"
                                dense
                                hide-details="true"
                                outlined
                              />
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
                <v-btn
                  class="btn-next"
                  color="primary"
                  @click="currentStep = stepperSteps.COLLECTION_TYPE.value + 1"
                >
                  {{ $t('action.next') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step
                v-if="stepperSteps.BILLING_INFORMATION.enabled"
                :complete="currentStep > stepperSteps.BILLING_INFORMATION.value"
                :step="stepperSteps.BILLING_INFORMATION.value"
                class="stepper"
                @click="currentStep = stepperSteps.BILLING_INFORMATION.value"
              >
                {{ $t('collectionPlan.steps.billingInformation') }}
              </v-stepper-step>

              <v-stepper-content
                v-if="stepperSteps.BILLING_INFORMATION.enabled"
                :step="stepperSteps.BILLING_INFORMATION.value"
              >
                <v-row v-if="additionalAccountingFields" style="max-width:calc(100% - 0.5em);padding-left:0.25em">
                  <v-col class="mt-3" cols="12">
                    <v-expansion-panels
                      id="additional-fields"
                      :value="[0, 1]"
                      focusable
                      multiple
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header v-if="additionalAccountingFieldsToDisplay.length">
                          {{ $t('collectionPlan.costCenters') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content v-if="additionalAccountingFieldsToDisplay.length">
                          <v-row class="mt-2">
                            <v-col
                              v-for="field in additionalAccountingFieldsToDisplay"
                              :key="field.name"
                              cols="12"
                              sm="6"
                            >
                              <v-text-field
                                v-model="meta.accounting[field.name]"
                                :label="field.name"
                                dense
                                hide-details="true"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <span v-if="false">{{ $t('collectionPlan.hints.costCenterInfo') }}</span>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel v-if="foreign && additionalBankingFields.length">
                        <v-expansion-panel-header>{{ $t('banking.bankingDetails') }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row class="mt-2">
                            <v-col
                              v-if="additionalBankingFields.includes('Kontoinhaber')"
                              cols="12"
                              sm="6"
                            >
                              <v-text-field
                                v-model="meta.external_fields.Kontoinhaber"
                                :label="$tc('banking.accountHolder')"
                                dense
                                hide-details="true"
                                outlined
                              />
                            </v-col>
                            <v-col
                              v-if="additionalBankingFields.includes('Bank')"
                              cols="12"
                              sm="6"
                            >
                              <v-text-field
                                v-model="meta.external_fields.Bank"
                                :label="$tc('banking.creditInstitution')"
                                dense
                                hide-details="true"
                                outlined
                              />
                            </v-col>
                            <v-col
                              v-if="additionalBankingFields.includes('IBAN')"
                              cols="12"
                              sm="6"
                            >
                              <v-text-field
                                v-model="meta.external_fields.IBAN"
                                dense
                                hide-details="true"
                                label="IBAN"
                                outlined
                              />
                            </v-col>
                            <v-col
                              v-if="additionalBankingFields.includes('BIC')"
                              cols="12"
                              sm="6"
                            >
                              <v-text-field
                                v-model="meta.external_fields.BIC"
                                dense
                                hide-details="true"
                                label="BIC"
                                outlined
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-if="additionalBankingFields.includes('Verwendungszweck')"
                                v-model="meta.external_fields.Verwendungszweck"
                                :label="$tc('banking.reference')"
                                dense
                                hide-details="true"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
                <v-btn
                  class="btn-next"
                  color="primary"
                  @click="currentStep = stepperSteps.BILLING_INFORMATION.value + 1"
                >
                  {{ $t('action.next') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step
                v-if="stepperSteps.COLLECTION_SCOPE.enabled"
                :complete="currentStep > stepperSteps.COLLECTION_SCOPE.value"
                :step="stepperSteps.COLLECTION_SCOPE.value"
                class="stepper"
                @click="currentStep = stepperSteps.COLLECTION_SCOPE.value"
              >
                {{ $t('collectionPlan.steps.collectionScope') }}
                <small v-if="showSummaries(stepperSteps.COLLECTION_SCOPE.value)">{{ step6summary }}</small>
              </v-stepper-step>

              <v-stepper-content
                v-if="stepperSteps.COLLECTION_SCOPE.enabled"
                :step="stepperSteps.COLLECTION_SCOPE.value"
              >
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-radio-group
                      v-model="scope"
                      :disabled="!isEditable"
                      class="mt-0 mb-0"
                      hide-details="auto"
                      mandatory
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-radio
                            :label="scopeOptions[0].name"
                            :value="scopeOptions[0].id"
                          ></v-radio>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-radio
                            :label="scopeOptions[1].name"
                            :value="scopeOptions[1].id"
                          ></v-radio>
                        </v-col>
                        <v-col v-if="this.scopeOptions.length >= 3" cols="12" sm="6">
                          <v-radio
                            :label="scopeOptions[2].name"
                            :value="scopeOptions[2].id"
                          >
                            <template slot="label">
                              <v-autocomplete
                                v-if="scope==='loc'"
                                v-model="location"
                                :items="locationOptions"
                                :menu-props="{ offsetY: true }"
                                dense
                                hide-details="auto"
                                item-text="name"
                                item-value="id"
                                label="Meine Orte"
                                outlined
                              ></v-autocomplete>
                            </template>
                          </v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-btn
                  class="btn-next"
                  color="primary"
                  @click="currentStep = stepperSteps.COLLECTION_SCOPE.value + 1"
                >
                  {{ $t('action.next') }}
                </v-btn>
              </v-stepper-content>

              <v-stepper-step
                :step="stepperSteps.FINALIZE.value"
                class="stepper"
                @click="currentStep = stepperSteps.FINALIZE.value"
              >
                {{ $t('collectionPlan.steps.finalize') }}
              </v-stepper-step>

              <v-stepper-content :step="stepperSteps.FINALIZE.value">
                <v-alert
                  v-if="!collectionName"
                  dense
                  outlined
                  type="error"
                >
                  {{ $t('collectionPlan.hints.mustEnterCollectionPurpose') }}
                </v-alert>
                <v-alert
                  v-if="detailDescription.length > this.maxDescriptionLength"
                  dense
                  outlined
                  type="error"
                >
                  max. {{ this.maxDescriptionLength }} Zeichen
                </v-alert>
                <v-row>
                  <div
                    v-for="(tg, index) in targets"
                    :key="index"
                    style="display:flex;margin-top:1em;margin-left:1em;margin-right: 0.5em"
                  >
                    <device-icon :device-class="tg" class="mr-2" size="20"></device-icon>
                    {{ DEVICE_CLASSES[tg] }}
                  </div><!-- FIXME Consolidate with CollectionCard -->
                </v-row>
                <v-row>
                  <v-col>
                    <div class="text-h4">{{ collectionName }}</div>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col>
                    <v-chip-group>
                      <v-chip
                        v-if="mode !== 'group'"
                        color="primary"
                        outlined
                        small
                      >
                        {{
                          collectionVariant === 'x' ? 'freie Kollekte' : collectionVariant === '_donation' ? 'Spendenzweck' : 'Nebenkollekte'
                        }}
                      </v-chip>
                      <v-chip
                        v-if="mode === 'group'"
                        color="primary"
                        outlined
                        small
                      >{{ $t('collectionPlan.labels.collectionGroup') }}</v-chip>
                      <v-chip
                        v-if="foreign"
                        color="primary"
                        outlined
                        small
                      >
                        {{ $t('collectionPlan.labels.foreignCollection') }}
                        <span v-if="meta.external_fields.Organisation">: {{ meta.external_fields.Organisation }} </span>
                      </v-chip>
                      <v-chip
                        v-if="allowRecurring"
                        color="primary"
                        outlined
                        small
                      >
                        {{ $t('collectionPlan.labels.recurringDonation') }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    {{ detailDescription }}
                  </v-col>
                </v-row>
                <v-row v-if="meta.external_fields.www">
                  <v-col cols="12">
                    {{ meta.external_fields.www }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-icon>mdi-clock-start</v-icon>
                    {{ start ? momentize(start) : 'ab sofort' }} - {{ end ? momentize(end) : 'unbegrenzt' }}
                  </v-col>
                </v-row>
                <v-row v-if="Object.values(meta.accounting).length">
                  <v-col cols="12">
                <span
                  v-for="(accountingValue, accountingField, accountingIndex) in meta.accounting"
                  :key="accountingField">
                  {{ accountingField }} {{ accountingValue }}<template
                  v-if="accountingIndex < Object.keys(meta.accounting).length - 1">,</template>
                </span>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        v-if="notYetStarted && isEditable && !isNew && mode !== 'group'"
        id="btn-delete"
        color="primary"
        elevation="0"
        text
        @click="deleteCollection"
      >
        {{ $t('collectionPlan.labels.deleteIrreversibly') }}
      </v-btn>
      <v-spacer/>
      <v-btn
        elevation="2"
        text
        @click="abort"
      >
        {{ $t('action.cancel') }}
      </v-btn>
      <v-btn
        id="btn-save"
        :disabled="saveButtonDisabled"
        :loading="saveInProgress"
        color="primary"
        elevation="2"
        @click="openConfirmDialog"
      >
        <v-icon v-if="saveError" color="#ae364b" left>mdi-alert</v-icon>
        <template v-if="isNew">{{ $t('action.create') }}</template>
        <template v-else>{{ $t('action.save') }}</template>
        <v-icon v-if="saveError" color="#ae364b" left>mdi-alert</v-icon>
      </v-btn>
    </v-card-actions>
    <base-dialog :is-open="isConfirmDialogOpen">
      <template #dialog-title>
        <span v-if="['new', 'duplicate'].includes(mode)">Sammlung erstellen?</span>
        <span v-else-if="mode === 'group'">Sammlungsgruppe erstellen?</span>
        <span v-else-if="mode === 'group-duplicate'">Sammlungsgruppe duplizieren?</span>
        <span v-else-if="mode === 'edit'">Wirklich bearbeiten?</span>
      </template>
      <template #dialog-content>
        <v-card-text>
          <span v-if="['new', 'duplicate'].includes(mode)">Möchten Sie die Sammlung wirklich erstellen?</span>
          <span v-else-if="mode === 'group'">Möchten Sie diese Sammlungsgruppe wirklich erstellen? Die Sammlungen können anschließend nicht wieder vereinzelt werden.</span>
          <span v-else-if="mode === 'group-duplicate'">Möchten Sie diese Sammlungsgruppe wirklich duplizieren?</span>
          <span v-else-if="mode === 'edit'">Möchten Sie die Sammlung wirklich bearbeiten?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            text
            color="primary"
            @click="isConfirmDialogOpen = false"
          >Nein, abbrechen
          </v-btn>
          <v-btn
            color="primary"
            @click="saveCollection"
          >Ja, {{ ['new', 'duplicate', 'group', 'group-duplicate'].includes(mode) ? 'erstellen' : 'bearbeiten '}}</v-btn>
        </v-card-actions>
      </template>
    </base-dialog>
  </v-card>
</template>

<script>
/* eslint-disable camelcase */
import { Settings } from 'luxon'
import moment from 'moment'
import { splitPurpose } from '@/lib/regex-tools'

import 'vue-datetime/dist/vue-datetime.css'
import '@fontsource/open-sans'
import {
  ADD_COLLECTION_PLAN, ADD_COLLECTION_PLAN_GROUP,
  DELETE_COLLECTION_PLAN, DELETE_COLLECTION_PLAN_GROUP, DUPLICATE_COLLECTION_PLAN_GROUP, FETCH_DATES_GLOBAL,
  FETCH_ORGANIZATION_COLLECTION_PLANS,
  FETCH_ORGANIZATIONS,
  UPDATE_COLLECTION_PLAN,
} from '@/store/action-types'
import { UPDATE_TIME } from '@/modules/common/store/action-types'
import DeviceIcon from '@/components/icons/DeviceIcon'
import { DEVICE_CLASSES, DEVICE_TYPES } from '@/lib/device-db'
import DisplayKindKA from './DisplayKindKA'
import DisplayKindKB from './DisplayKindKB'
import DisplayKindSP from './DisplayKindSP'
import DisplayKindKollekteApp from './DisplayKindKollekteApp'
import DisplayKindSpendeApp from './DisplayKindSpendeApp'
import { mapGetters, mapState } from 'vuex'
import { removeEmptyProperties } from '@/lib/settings-tools'
import { bankingExtraFields, foreignCollectionExtraFields } from "@/lib/collection-plan-utils"
import DisplayKindSO from './DisplayKindSO'
import BaseDialog from "@/components/UI/BaseDialog.vue"

const fieldMustNotBeEmptyRule = v => !!v || 'Dieses Feld darf nicht leer sein'

export default {
  name: 'PlanEditor',
  components: {
    BaseDialog,
    DeviceIcon
  },
  filters: {
    extractPreposition: function (value) {
      return splitPurpose(value)?.preposition ?? ''
    },
    extractPurpose: function (value) {
      return splitPurpose(value)?.purpose ?? ''
    }
  },
  props: {
    locations: { // Map of available location url => location object
      type: Object,
      required: false,
      default: () => {
      },
    },
    dates: { // Map of available date url => date object
      type: Object,
      required: false,
      default: () => {
      },
    },
    organization: { // Organization object in whose context to operate
      type: Object,
      required: true
    }
  },
  emits: ['collection-plan-group-created'],
  data () {
    return {
      // Input fields
      collectionName: '',
      collectionVariant: '_donation',
      meta: {
        accounting: {},
        external_fields: {},
      },
      selectedTargets: {},
      displayVariants: {},
      selectedDate: 'custom_date',
      scope: 'org',
      location: null,
      start: null,
      end: null,
      foreign: false,
      detailDescription: '',
      // State
      mode: "new", // One of "new", "edit", "duplicate", "view", "group"
      inputCollection: null,
      saveInProgress: false,
      saveError: false,
      formValid: false,
      currentStep: 1,
      delayedEnableContentEditable: false,

      // Form rules
      collectionNameRules: [fieldMustNotBeEmptyRule],

      // Other, and constants
      DEVICE_CLASSES,
      variantOptions: [ // Note: On save, map to type
        {
          id: 'inner',
          name: 'Hauptkollekte'
        },
        {
          id: '_donation',
          name: 'Spendenzweck'
        },
        {
          id: 'outer',
          name: 'Nebenkollekte'
        },
        {
          id: 'x',
          name: 'freie Kollekte'
        }
      ],
      dateProps: {},
      timeProps: {
      },
      displayKindComponents: {
        KA: DisplayKindKA,
        KB: DisplayKindKB,
        'spende.app': DisplayKindSpendeApp,
        'kollekte.app': DisplayKindKollekteApp,
        SP: DisplayKindSP,
        SO: DisplayKindSO,
      },
      allowRecurring: false,
      groupCandidates: [],
      groupCandidateHeaders: [
        {
          text: this.$tc('collectionPlan.share'),
          value: 'weight',
          width: '150px'
        },
        {
          text: this.$tc('collectionPlan.collection'),
          value: 'name'
        }
      ],
      isConfirmDialogOpen: false
    }
  },
  async mounted () {
    await this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS)
    await this.$store.dispatch('date/' + FETCH_DATES_GLOBAL)
  },
  computed: {
    ...mapState(['now']),
    ...mapGetters('organization', ['allOrganizations']),
    ...mapGetters('date', ['datesListForUrl']),
    ...mapGetters('user', ['isSuperuser']),
    ...mapGetters('collectionPlan', ['collectionPlanGroupsByUrl']),
    isNew () {
      return ['new', 'duplicate'].includes(this.mode)
    },
    isTitleEditable () {
      const allowedModes = ['new', 'duplicate', 'edit']

      if (this.isSuperuser) {
        allowedModes.push('group')
        allowedModes.push('group-duplicate')
      }

      return allowedModes.includes(this.mode)
    },
    isEditable () {
      return ['new', 'duplicate', 'edit', 'group', 'group-duplicate'].includes(this.mode)
    },
    isRestrictedEditable () {
      return this.isEditable || (['view'].includes(this.mode) && this.notYetStarted)
    },
    startReference () {
      return this.now.subtract(1, 'hour')
    },
    step2Rules () {
      return [
        () => !this.isNew ||
          (this.end === null || (this.end > this.start && this.end > this.startReference))
      ]
    },
    step3Rules () {
      return [() => (this.detailDescription.length <= this.maxDescriptionLength)]
    },
    atLeastOneDeviceClassEnabledRule () {
      if (this.mode === 'group') {
        return () => true
      }
      return () => this.targets.length > 0 || "Mindestens ein Gerät muss für die Sammlung ausgewählt werden."
    },
    allDeviceTypesEnabled () {
      if (this.mode === 'group') {
        return false
      }

      if (this.$store.state?.user?.user?.is_superuser) {
        return true
      }
      return ['hannah'].includes(this.$store.state?.user?.user?.username ?? null)
    },
    orgdevices () {
      return this.$store.getters['organization/organizationRelevantDeviceTypesForUrl'](this?.organization?.url ?? null)
    },
    ownDeviceClasses () {
      const retval = new Set()
      for (const [deviceType, ddbEntry] of Object.entries(DEVICE_TYPES)) {
        if (this.orgdevices.has(deviceType)) {
          retval.add(ddbEntry.deviceClass)
        }
      }
      return retval
    },
    allowedDeviceClasses () {
      if (this.mode === 'group') {
        const allowedDeviceClasses = []
        this.groupCandidates.forEach((candidate) => {
          allowedDeviceClasses.push(...candidate.targets)
        })
        return new Set(allowedDeviceClasses)
      }

      if (this.allDeviceTypesEnabled) {
        return new Set(Object.keys(DEVICE_CLASSES))
      }

      return this.ownDeviceClasses
    },
    ownDisplayKinds () {
      const retval = new Set()
      for (const [deviceType, ddbEntry] of Object.entries(DEVICE_TYPES)) {
        if (this.orgdevices.has(deviceType) && ddbEntry?.display?.kind) {
          retval.add(ddbEntry.display.kind)
        }
      }
      return retval
    },
    allowedDisplayKinds () {
      if (this.allDeviceTypesEnabled || this.mode === 'group') {
        return new Set(
          Object.values(DEVICE_TYPES)
            .filter(ddbEntry => !!ddbEntry?.display?.kind)
            .map(ddbEntry => ddbEntry.display.kind)
        )
      }
      return this.ownDisplayKinds
    },
    editorLayout () {
      /* returns [ { deviceClasses: Set, displayKinds: Set }, ... ] */
      const retval = []
      // TODO: Show only group candidate enabled devices
      for (const ddbEntry of Object.values(DEVICE_TYPES)) {
        // Find out if this deviceClass and displayKind are allowed
        if (!this.allowedDeviceClasses.has(ddbEntry.deviceClass)) {
          continue
        }
        if (!ddbEntry?.display?.kind) {
          continue
        }
        if (!this.allowedDisplayKinds.has(ddbEntry?.display?.kind)) {
          continue
        }
        // Find out if either this deviceClass or displayKind are already assigned, otherwise create new column
        let column = retval.find(item => item.deviceClasses.has(ddbEntry.deviceClass) || item.displayKinds.has(ddbEntry.display.kind))
        if (column === undefined) {
          retval.push(column = {
            deviceClasses: new Set(),
            displayKinds: new Set()
          })
        }
        column.deviceClasses.add(ddbEntry.deviceClass)
        column.displayKinds.add(ddbEntry.display.kind)
      }
      return retval
    },
    selectedDisplayKinds () {
      const retval = {}
      for (const ddbEntry of Object.values(DEVICE_TYPES)) {
        if (ddbEntry?.display?.kind) {
          retval[ddbEntry.display.kind] = !!retval[ddbEntry.display.kind] || this.selectedTargets[ddbEntry.deviceClass]
        }
      }
      return retval
    },
    step2summary () {
      // FIXME Date
      return (this.variantOptions.find(item => item.id === this.collectionVariant) ?? {})?.name ?? ''
    },
    step6summary () {
      // FIXME grammar, location
      return (this.scopeOptions.find(item => item.id === this.scope) ?? {})?.name ?? ''
    },
    dateOptions () {
      const retval = []
      retval.push({
        url: 'custom_date',
        name: 'freie Zeiteintragung'
      })
      if (this?.organization?.url) {
        const dateSorted = Array.from(this.datesListForUrl(this.organization.url)).filter(item => (moment(item.day).diff(moment(), 'days') > -8))

        dateSorted.sort((a, b) => moment(a.day).diff(moment(b.day)))
        dateSorted.forEach((date) => {
          retval.push({
            url: date.url,
            name: `${date.name} (${moment(date.day).format('LLLL')})`
          })
        })
      }
      return retval
    },
    scopeOptions () {
      const retval = [
        {
          id: 'org',
          name: 'diese Organisation'
        },
        {
          id: 'inherit',
          name: 'diese Organisation und allen Unterorganisationen'
        }
      ]
      if (this.locationOptions.length > 0) {
        retval.push({
          id: 'loc',
          name: 'für einen bestimmten Ort'
        })
      }
      return retval
    },
    locationOptions () {
      return Object.keys(
        this.locations
      ).filter(
        (url) => this.locations[url].organization === this.organization?.url
      ).map(
        (url) => {
          return {
            id: url,
            name: this.locations[url].name
          }
        }
      )
    },
    organizationSettings () {
      return this.$store.getters['organization/organizationSettingsByUrl'][this.organization?.url ?? null] ?? {}
    },
    additionalAccountingFields () {
      return this.organizationSettings?.accounting?.extra_fields ?? null
    },
    additionalAccountingFieldsToDisplay () {
      return this.additionalAccountingFields.filter(field => field?.type !== 'confirmed')
    },
    extraFields () {
      return this.organizationSettings?.external_fields?.extra_fields ?? null
    },
    additionalForeignCollectionFields () {
      return this.determineExtraFields(foreignCollectionExtraFields)
    },
    additionalBankingFields () {
      return this.determineExtraFields(bankingExtraFields)
    },
    additionalWebsiteField () {
      return this.determineExtraFields(['www'])
    },
    notYetStarted () {
      if (this.inputCollection?.start) {
        return this.now < moment(this.inputCollection.start)
      }
      return true
    },
    startDateTextFieldProps () {
      return {
        outlined: true,
        dense: true,
        clearable: true,
        hideDetails: 'auto',
        error: this.isInvalidStartDate,
        hint: this.isInvalidStartDate ? 'Das Startddatum darf nicht nach dem Enddatum liegen' : null
      }
    },
    endDateTextFieldProps () {
      return {
        outlined: true,
        dense: true,
        clearable: true,
        hideDetails: 'auto',
        error: this.isInvalidEndDate,
        hint: this.isInvalidEndDate ? 'Das Enddatum darf nicht in der Vergangenheit oder vor dem Startdatum liegen' : null
      }
    },
    isInvalidStartDate () {
      // To avoid the headache of trying to calculate valid time ranges
      // Hannah and I decided to just disable the start and end validation
      // for liturgical calendar days for now. The API will give you only valid dates anyway.
      if (this.selectedDate !== 'custom_date') {
        return false
      }
      const start = moment(this.start)
      return start.isBefore(moment())
    },
    isInvalidEndDate () {
      // See #isInvalidStartDate
      if (this.selectedDate !== 'custom_date') {
        return false
      }
      const start = moment(this.start)
      const end = moment(this.end)
      return (this.end !== null && (end.isBefore(start) || end.isBefore(moment(this.startReference))))
    },
    saveButtonDisabled () {
      return !this.formValid || this.isInvalidStartDate || this.isInvalidEndDate
    },
    targets () {
      return Object.keys(this.selectedTargets).filter(
        key => this.selectedTargets[key] && (
          this.allowedDeviceClasses.has(key) ||
          this.scope === 'inherit'
        )
      )
    },
    useGroupMode () {
      let isCollectionPlanGroupParent = false
      if (this.inputCollection?.url) {
        isCollectionPlanGroupParent = this.isCollectionPlanGroupParent(this.inputCollection.url)
      }

      return (['group', 'group-duplicate'].includes(this.mode) || isCollectionPlanGroupParent)
    },
    allowDeviceSelection () {
      let isCollectionPlanGroupParent = false
      if (this.inputCollection?.url) {
        isCollectionPlanGroupParent = this.isCollectionPlanGroupParent(this.inputCollection.url)
      }

      return !(['group'].includes(this.mode) || isCollectionPlanGroupParent)
    },
    stepperSteps () {
      const skipMoreInformationStep = (this.selectedTargets.WA || this.selectedTargets.WS || this.useGroupMode) ? 0 : 1
      const skipPeriodSelectionStep = (!this.useGroupMode && this.mode !== 'group-duplicate') ? 0 : 1
      const skipCollectionTypeStep = (this.collectionVariant !== '_donation') ? 0 : 1
      const skipBillingInformationStep = (this.additionalAccountingFields !== null && !this.useGroupMode) ? 0 : 1

      const inlineLastStep = (skipPeriodSelectionStep && !skipMoreInformationStep) ? 1 : 0
      return {
        DEVICE_SELECTION: {
          enabled: true,
          value: 1
        },
        PERIOD_SELECTION: {
          enabled: this.mode !== 'group',
          value: 2
        },
        MORE_INFORMATION: {
          enabled: this.selectedTargets.WA || this.selectedTargets.WS || this.useGroupMode,
          value: 3 - skipPeriodSelectionStep
        },
        COLLECTION_TYPE: {
          enabled: (this.collectionVariant !== "_donation"),
          value: 4 - skipPeriodSelectionStep - skipMoreInformationStep - skipBillingInformationStep
        },
        BILLING_INFORMATION: {
          enabled: (this.additionalAccountingFields !== null) && !this.useGroupMode,
          value: 5 - skipPeriodSelectionStep - skipMoreInformationStep - skipCollectionTypeStep
        },
        COLLECTION_SCOPE: {
          enabled: !this.useGroupMode,
          value: 6 - skipPeriodSelectionStep - skipMoreInformationStep - skipCollectionTypeStep - skipBillingInformationStep
        },
        FINALIZE: {
          enabled: true,
          value: 7 - inlineLastStep - skipPeriodSelectionStep - skipMoreInformationStep - skipBillingInformationStep - skipCollectionTypeStep
        }
      }
    },
    recurringPaymentsEnabled () {
      // FIXME This may need to be evaluated server-side in the future -> may need its own database field
      return this.organizationSettings?.features?.allow_recurring_payments ?? false
    },
    isEligibleForRecurringDonation () {
      if (!this.recurringPaymentsEnabled) {
        return false
      }

      if (this.collectionVariant !== '_donation') {
        return false
      }

      if (this.start && !this.end) {
        return true
      }

      const start = moment(this.start)
      const end = moment(this.end)
      const duration = moment.duration(end.diff(start))
      return duration.asMonths() >= 6
    },
    textToShow () {
      const calenderSetting = this.getCalendarSetting(this.selectedDate)
      const result = this.messageBasedOnDate(this.selectedDate)
      if (calenderSetting) {
        return (result) ? this.getMessageForEqualDays(calenderSetting) : this.getMessageForUnEqualDays(calenderSetting)
      } else {
        return ''
      }
    },
    datePickerProps () {
      return {
        min: this.now.toISOString()
      }
    },
    timePickerProps () {
      return {
        format: '24hr'
      }
    },
    maxDescriptionLength () {
      return ['group', 'group-duplicate'].includes(this.mode) ? 5000 : 1000
    },
    detailDescriptionRules () {
      return [v => v.length <= this.maxDescriptionLength || 'max. ' + this.maxDescriptionLength + ' Zeichen']
    }
  },
  methods: {
    async changeCollectionName () {
      this.delayedEnableContentEditable = false
      await this.$nextTick()
      for (const displayKind of this.allowedDisplayKinds) {
        this.$set(this.displayVariants, displayKind, this.collectionName ?? '')
      }
      await this.$nextTick()
      this.delayedEnableContentEditable = true
    },
    openConfirmDialog () {
      this.isConfirmDialogOpen = true
    },
    async saveCollection () {
      this.$refs.form.validate()
      this.isConfirmDialogOpen = false
      if (this.formValid) {
        this.saveError = false

        let cType = null
        let cVariant = null
        if (this.collectionVariant === '_donation') {
          cType = 'donation'
        } else {
          cType = 'collection'
          cVariant = this.collectionVariant
        }

        const display = {}
        // map displayVariants[displayKind] to display[deviceType]
        for (const [deviceType, ddbEntry] of Object.entries(DEVICE_TYPES)) {
          if (!this.allowedDeviceClasses.has(ddbEntry.deviceClass)) {
            continue
          }
          if (ddbEntry?.display?.kind) {
            display[deviceType] = this.displayVariants[ddbEntry.display.kind] || this.collectionName
          }
        }
        // Assign default and remove all entries that match the default
        display.default = this.collectionName
        for (const deviceType of Object.keys(DEVICE_TYPES)) {
          if (display[deviceType] === display.default) {
            delete display[deviceType]
          }
        }

        let meta = this.meta || {}
        meta = {
          accounting: removeEmptyProperties(meta?.accounting ?? {}),
          description: removeEmptyProperties(meta?.description ?? {}),
          external_fields: removeEmptyProperties(meta?.external_fields ?? {})
        }

        if (!this.foreign) {
          const www = meta.external_fields?.www || null
          meta.external_fields = {}

          if (www) {
            meta.external_fields.www = www
          }
        }

        meta = removeEmptyProperties(meta)
        if (Object.keys(meta).length === 0) {
          meta = null
        }

        let collectionPlan = ["new", "duplicate"].includes(this.mode)
          ? {
              active: true,
              choice: false,
              parent: null,
            }
          : { ...this.inputCollection }

        // When start is not set, it means it will start immediately
        const start = this.start ? this.start : new Date()

        collectionPlan = {
          ...collectionPlan,
          foreign: this.foreign,
          inherit: this.scope === 'inherit',
          collection_variant: cVariant,
          type: cType,
          date: this.selectedDate === 'custom_date' ? null : this.selectedDate,
          start: this.selectedDate === 'custom_date' ? start : null,
          end: this.selectedDate === 'custom_date' ? this.end : null,
          allow_recurring: this.allowRecurring ?? false,
          name: this.collectionName,
          organization: null,
          location: null,
          meta,
          targets: this.targets,
          display,
        }

        if (['inherit', 'org'].includes(this.scope) || ['group-duplicate'].includes(this.mode)) {
          collectionPlan.organization = this.inputCollection?.organization || this.organization?.url
        } else {
          collectionPlan.location = this.location || this.inputCollection?.location || null
        }

        collectionPlan.start = collectionPlan?.start || null
        collectionPlan.end = collectionPlan?.end || null

        if (this.detailDescription) {
          collectionPlan.meta = collectionPlan?.meta || {}
          collectionPlan.meta.description = collectionPlan?.meta?.description || {}
          collectionPlan.meta.description.text = this.detailDescription
        }

        let collectionPlanGroupCandidates = []
        if (this.mode === 'group-duplicate') {
          collectionPlanGroupCandidates = this.groupCandidates.map((groupCandidate) => {
            return {
              ...groupCandidate,
              organization: collectionPlan.organization,
              start: collectionPlan.start,
              end: collectionPlan.end,
              date: collectionPlan.date
            }
          })
        }

        try {
          this.saveInProgress = true
          let result
          if (["new", "duplicate"].includes(this.mode)) {
            result = await this.$store.dispatch('collectionPlan/' + ADD_COLLECTION_PLAN, collectionPlan)
          } else if (this.mode === 'edit') {
            result = await this.$store.dispatch('collectionPlan/' + UPDATE_COLLECTION_PLAN, collectionPlan)
          } else if (this.mode === 'group') {
            result = await this.$store.dispatch('collectionPlan/' + ADD_COLLECTION_PLAN_GROUP, {
              collectionPlanGroup: collectionPlan,
              collectionPlanMembers: this.groupCandidates
            })
            this.$emit('collection-plan-group-created')
          } else if (this.mode === 'group-duplicate') {
            result = await this.$store.dispatch('collectionPlan/' + DUPLICATE_COLLECTION_PLAN_GROUP, {
              collectionPlanGroup: collectionPlan,
              collectionPlanMembers: collectionPlanGroupCandidates
            })
            this.$emit('collection-plan-group-created')
          }
          if (!result) {
            this.saveError = true
          } else {
            await this.abort()
          }
          await this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, this.organization.url)
        } finally {
          this.saveInProgress = false
          await this.$store.dispatch(UPDATE_TIME)
        }
      }
    },
    deleteCollection () {
      if (confirm('Unwiderruflich löschen?')) {
        this.processDelete()
      }
    },
    async processDelete () {
      this.saveError = false
      let result = false
      if (this.isCollectionPlanGroupParent(this.inputCollection.url)) {
        result = await this.$store.dispatch('collectionPlan/' + DELETE_COLLECTION_PLAN_GROUP, { collectionPlanGroup: this.inputCollection })
      } else {
        result = await this.$store.dispatch('collectionPlan/' + DELETE_COLLECTION_PLAN, this.inputCollection)
      }
      if (result !== true) {
        this.saveError = true
      } else {
        await this.abort()
      }
      await this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, this.organization.url)
    },
    async abort () {
      await this.$emit('closeEditor')
      await this.$nextTick()
      this.reset()
    },
    reset () {
      this.selectedDate = 'custom_date'
      this.collectionName = null
      this.meta = {
        accounting: {},
        external_fields: {}
      }
      this.start = null
      this.end = null
      this.foreign = false
      this.collectionVariant = null
      this.scope = 'org'
      this.resetDynamicFields()
      this.collectionVariant = '_donation'
      this.location = null
      this.saveError = false
    },
    resetDynamicFields () {
      for (const deviceClass of Object.keys(DEVICE_CLASSES)) {
        this.$set(this.selectedTargets, deviceClass, this.ownDeviceClasses.has(deviceClass))
      }
      for (const displayKind of this.allowedDisplayKinds) {
        this.$set(this.displayVariants, displayKind, null)
      }
    },
    async init ({ mode, collection = null, dateUrl = null, groupCandidates = [] }) {
      if (["edit", "view", "duplicate"].includes(mode) && !collection) {
        throw new Error(`With mode {mode} a collection object must be specified`)
      }
      if (["edit", "view"].includes(mode) && !collection?.url) {
        throw new Error(`With mode {mode} a collection object with url must be specified`)
      }
      if (!["new", "duplicate", "edit", "view", "group", "group-duplicate"].includes(mode)) {
        throw new Error(`Invalid mode {mode}`)
      }

      this.delayedEnableContentEditable = false
      this.$nextTick(() => {
        Settings.defaultLocale = 'de'
        this.resetDynamicFields()
        this.mode = mode
        this.inputCollection = Object.assign({}, collection)
        // let isGroupParent = false
        // if (collection?.url) {
        //   isGroupParent = this.isCollectionPlanGroupParent(collection.url)
        // }

        if (mode === 'duplicate') {
          this.inputCollection.id = null
          this.inputCollection.url = null
          this.inputCollection.organization = this.organization.url
        }

        this.collectionName = collection?.name || ""

        if (['group', 'group-duplicate'].includes(mode)) {
          this.groupCandidates = groupCandidates
          this.collectionName = this.getCollectionPlanGroupName()
          this.changeCollectionName()
        } else {
          this.groupCandidates = []
        }

        if (dateUrl) {
          this.selectedDate = dateUrl
        } else if (['group', 'group-duplicate'].includes(mode)) {
          this.start = this.groupCandidates[0].start
          this.end = this.groupCandidates[0].end
        } else if (['duplicate'].includes(mode)) {
          this.start = null
          this.end = null
        } else {
          this.start = collection?.start ? new Date(collection.start) : null // new Date() // Default start to input or now
          this.end = collection?.end ? new Date(collection.end) : null // Default end to input or unset
          this.selectedDate = collection?.date || "custom_date"
        }

        this.allowRecurring = collection?.allow_recurring ?? false

        if (collection?.type === 'donation') {
          this.collectionVariant = '_donation'
        } else {
          // eslint-disable-next-line camelcase
          this.collectionVariant = collection?.collection_variant || '_donation'
        }

        if (collection) {
          this.meta = collection.meta || {
            accounting: {},
            external_fields: {},
            ...(this.collection?.meta ?? {})
          }
          this.foreign = collection?.foreign || false

          if (!collection.meta?.accounting) {
            this.meta.accounting = {}
          }
          if (!collection.meta?.external_fields) {
            this.meta.external_fields = {}
          }
        } else {
          this.meta = {
            accounting: {},
            external_fields: {},
            ...(this.collection?.meta ?? {})
          }
          this.foreign = this.collection?.foreign || false
        }

        if (['group', 'group-duplicate'].includes(mode)) {
          this.detailDescription = this.groupCandidates
            .map(candidate => candidate.meta?.description?.text || "")
            .filter(description => description.trim().length)
            .join(" & ")
        } else {
          this.detailDescription = this.meta?.description?.text || ""
        }

        if (['group', 'group-duplicate'].includes(mode)) {
          this.scope = this.groupCandidates[0].inherit ? 'inherit' : 'org'
        } else if (collection?.inherit && collection?.organization) {
          this.scope = 'inherit'
        } else if (collection?.organization) {
          this.scope = 'org'
        } else if (collection?.location && Object.keys(this.locations).length > 0) {
          this.scope = 'loc'
        } else {
          this.scope = "org"
        }

        this.location = collection?.location || this.locationOptions[0]?.id || null

        for (const deviceClass of Object.keys(DEVICE_CLASSES)) {
          if (mode !== "new" && !['group', 'group-duplicate'].includes(mode)) {
            this.$set(this.selectedTargets, deviceClass, (collection?.targets ?? []).includes(deviceClass))
          } else if (['group', 'group-duplicate'].includes(mode)) {
            this.groupCandidates[0].targets.forEach(target => {
              this.selectedTargets[target] = true
            })
          } else {
            this.$set(this.selectedTargets, deviceClass, this.allowedDeviceClasses.has(deviceClass))
          }
        }

        // Map from collection.display[deviceType] to displayVariants[displayKind]
        // Warning: Duplicate displayKinds will be overwritten (should be safe)
        for (const [deviceType, value] of Object.entries(collection?.display ?? {})) {
          if ((DEVICE_TYPES[deviceType] ?? {})?.display?.kind) {
            this.$set(this.displayVariants, DEVICE_TYPES[deviceType].display.kind, value ?? null)
          }
        }
        // Fill all other displayVariants with default
        for (const displayKind of this.allowedDisplayKinds) {
          if (!this.displayVariants[displayKind]) {
            this.$set(this.displayVariants, displayKind, collection?.display?.default ?? collection?.name ?? '')
          }
        }

        this.$refs.form.resetValidation()

        this.$nextTick(() => {
          // Contenteditable needs a tick
          this.delayedEnableContentEditable = true
        })
      })
    },
    momentize (momDate) {
      return moment(momDate).format('llll')
    },
    showSummaries (stepNo) {
      // Don't show summaries on last page
      if (this.currentStep >= 8) {
        return false
      }
      // always show small summaries when editing, except for self
      if (this.collection) {
        return this.currentStep !== stepNo
      }
      // Otherwise, show small summaries en route
      return this.currentStep > stepNo
    },
    determineExtraFields (fieldList) {
      if (!this.extraFields) {
        return []
      }

      const availableFields = []

      for (const extraField of this.extraFields) {
        if (fieldList.includes(extraField.name)) {
          availableFields.push(extraField.name)
        }
      }

      return availableFields
    },
    messageBasedOnDate (selectedDateUrl) {
      const calenderSetting = this.getCalendarSetting(selectedDateUrl)
      let isEqual = false
      if (calenderSetting) {
        isEqual = (calenderSetting.other_postrun_days === calenderSetting.sunday_postrun_days && calenderSetting.other_prerun_days === calenderSetting.sunday_prerun_days)
      }
      return isEqual
    },
    getCalendarSetting (selectedDateUrl) {
      const dateList = this.datesListForUrl(this.organization.url)
      const selectedDate = dateList.find(obj => obj.url === selectedDateUrl)
      let date = new Date(selectedDate.day).toISOString()
      date = date.slice(0, date.lastIndexOf("."))
      const organizationUrl = selectedDate.organization
      return this.getRecursiveCalendarSetting(organizationUrl, date)
    },
    getRecursiveCalendarSetting (organizationUrl, date) {
      while (organizationUrl) {
        const currentOrg = this.allOrganizations[organizationUrl]
        const calender_settings = currentOrg.calendar_settings.filter(setting => this.isValidCalendarSetting(setting, date))
        if (calender_settings && calender_settings.length > 0) {
          return calender_settings[0]
        }
        if (currentOrg?.flags.includes('no_inherit_calendar')) {
          return null
        }
        organizationUrl = this.allOrganizations[organizationUrl].parent
      }
      return null
    },
    isValidCalendarSetting (calender_setting, date) {
      return (calender_setting.valid_from === null || calender_setting.valid_from <= date) && (calender_setting.valid_notafter === null || date < calender_setting.valid_notafter)
    },
    getMessageForEqualDays (calenderSetting) {
      const sundayPrerunDays = calenderSetting.sunday_prerun_days
      const sundayPostrunDays = calenderSetting.sunday_postrun_days
      const sundayPrerunTime = new Date(`1970-01-01 ${calenderSetting.sunday_prerun_time}`).getHours()
      const sundayPostrunTime = new Date(`1970-01-01 ${calenderSetting.sunday_postrun_time}`).getHours()
      const sundayTotalDays = sundayPrerunDays + 1 + sundayPostrunDays
      return `Der vorbelegte Gültigkeitszeitraum für liturgische Tage sind immer ${sundayTotalDays} ${sundayTotalDays === 1 ? 'Tag' : 'Tage'}` +
        `: ${sundayPrerunDays}${sundayPrerunDays === 1 ? ' Tag' : ' Tage'} davor ${sundayPrerunTime} Uhr und ` +
        `${sundayPostrunDays}${sundayPostrunDays === 1 ? ' Tag' : ' Tage'} danach ${sundayPostrunTime} Uhr.`
    },
    getMessageForUnEqualDays (calenderSetting) {
      const sundayPrerunDays = calenderSetting.sunday_prerun_days
      const sundayPostrunDays = calenderSetting.sunday_postrun_days
      const sundayPrerunTime = new Date(`1970-01-01 ${calenderSetting.sunday_prerun_time}`).getHours()
      const sundayPostrunTime = new Date(`1970-01-01 ${calenderSetting.sunday_postrun_time}`).getHours()
      const otherPrerunDays = calenderSetting.other_prerun_days
      const otherPostrunDays = calenderSetting.other_postrun_days
      const otherPrerunTime = new Date(`1970-01-01 ${calenderSetting.other_prerun_time}`).getHours()
      const otherPostrunTime = new Date(`1970-01-01 ${calenderSetting.other_postrun_time}`).getHours()
      const sundayTotalDays = sundayPrerunDays + 1 + sundayPostrunDays
      const otherTotalDays = otherPrerunDays + 1 + otherPostrunDays
      return `Der vorbelegte Gültigkeitszeitraum für Sonntage sind immer ${sundayTotalDays} ${sundayTotalDays === 1 ? ' Tag' : ' Tage'}` +
        `: ${sundayPrerunDays}${sundayPrerunDays === 1 ? ' Tag' : ' Tage'} vor dem Sonntag, ${sundayPrerunTime} ` +
        `Uhr und ${sundayPostrunDays} ${sundayPostrunDays === 1 ? ' Tag' : ' Tage'} ` +
        `nach dem Sonntag, ${sundayPostrunTime} Uhr.\nDer vorbelegte Gültigkeitszeitraum für andere Feiertage ` +
        `sind immer ${otherTotalDays} ${otherTotalDays === 1 ? ' Tag' : ' Tage'}: ${otherPrerunDays} ${otherPrerunDays === 1 ? ' Tag' : ' Tage'} ` +
        `vor dem Sonntag, ${otherPrerunTime} Uhr und ${otherPostrunDays}${otherPostrunDays === 1 ? ' Tag' : ' Tage'} nach dem Sonntag, ${otherPostrunTime} Uhr.`
    },
    changeWeight (collection, weightChange) {
      collection.weight += weightChange

      if (collection.weight < 1) {
        collection.weight = 1
      }
    },
    getCollectionPlanGroupName () {
      const collectionPlanNames = this.groupCandidates.map(candidate => candidate.name)
      return collectionPlanNames.join(', ').replace(/, ([^,]*)$/, ' & $1')
    },
    isCollectionPlanGroupParent (collectionUrl) {
      return !!this.collectionPlanGroupsByUrl[collectionUrl]
    },
  },
  watch: {
    isEligibleForRecurringDonation: {
      handler (newValue) {
        if (newValue < 6) {
          this.allowRecurring = false
        }
      }
    },
    targets: {
      handler () {
        this.$refs.form.validate()
      }
    },
    selectedDate: {
      immediate: true,
      handler (newValue) {
        if (newValue === 'custom_date') {
          if (!this.start) {
            this.start = null
          }
        } else {
          const dateObj = this.dates[newValue] ?? null
          if (dateObj) {
            this.start = new Date(dateObj.start)
            this.end = new Date(dateObj.end)
          }
        }
      }
    },
    scope: {
      immediate: true,
      handler (newValue) {
        if (newValue === 'loc') {
          if (!this.location) {
            this.location = this.locationOptions[0].id
          }
          /* Disable all devices classes that are software */
          for (const ddbEntry of Object.values(DEVICE_TYPES)) {
            if (ddbEntry?.webApp) {
              this.$set(this.selectedTargets, ddbEntry.deviceClass, false)
            }
          }
        }
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
.stepper
  cursor: pointer

.vdatetime-input
  border: 1px solid green
  width: 100%

.simulation-wrapper.disabled
  filter: blur(8px) grayscale(100%)

.btn-next
  margin-top: 20px

</style>
