export const READ_CPLAN_INHERITED = "READ_CPLAN_INHERITED"
export const READ_NONSENSITIVE = "READ_NONSENSITIVE"
export const READ_CPLAN = "READ_CPLAN"
export const WRITE_CPLAN = "WRITE_CPLAN"
export const READ_ORG_DATA = "READ_ORG_DATA"
export const WRITE_ORG_DATA = "WRITE_ORG_DATA"
export const READ_ACCOUNTS = "READ_ACCOUNTS"
export const WRITE_ACCOUNTS = "WRITE_ACCOUNTS"
export const MANAGE_USERS = "MANAGE_USERS"
export const READ = "READ"
export const WRITE = "WRITE"
export const MEMBER = "MEMBER"

export const PERM_TYPE_INTERACT_GENERAL = "interactGeneral"
export const PERM_TYPE_INTERACT_COLLECTION_PLAN = "interactCollectionPlan"
export const PERM_TYPE_INTERACT_WRITE_ANY = "interactWriteAny"
export const PERM_TYPE_READ_OTHER = "readOther"

export const MINOR_RIGHTS = new Set([READ_CPLAN_INHERITED, READ_NONSENSITIVE])
